<template style="height: 100%;">

	<div>
		<div v-if="!show" class="_display-none _mb-flex-row-mid _mb-font-28 _mb-pad-x-30 _mb-pad-y-20" style="background-color:rgba(248, 234, 235, 1);color:rgba(200, 25, 32, 1);">
			<div class="iconfont icon-gantanhaozhong  _mb-mar-right-10 _mb-font-36"></div>
			<div>{{ $t('验证码已发送至您的邮箱，请注意') }}</div>
		</div>
		<div class="index _flex-row-center _pc-pad-top-60 _mb-pad-top-45">
			<div class="main-box _pc-w-475 _mb-w-max _mb-pad-x-30">
				<div class="title _pc-font-30 _b _pc-mar-bottom-40 _mb-font-48 _mb-mar-bottom-30 _mb-flex-row-bet-mid" style="color: #333333;">
					<div>{{ $t('注册') }}</div>

					<router-link :to="'/login'" class="_display-none _mb-flex-row-mid _mb-font-28" style="align-self:flex-end;color:rgba(66, 132, 220, 1);">
						<img class="_mb-w-20 _mb-mar-right-10" src="~@/assets/image/hangda/fanhui2.png" alt="">
						<div>{{ $t('返回登录') }}</div>
					</router-link>
				</div>
				<!-- PC -->
				<div class="_mb-display-none">
					<!-- 邮箱 -->
					<div class="main-li _pc-mar-bottom-25">
						<div class="_flex-row-mid">
							<img class="_pc-mar-right-10 _pc-w-15" src="~@/assets/image/hangda/logo/mail.png" alt="" />
							<div class="_pc-font-16" style="color: #333333;">{{ $t('电子邮箱') }}</div>
						</div>
						<div class="input-box _pc-w-max _pc-h-50 _white-bg _flex-row-mid _pc-pad-x-25">
							<input type="text" style="width: 100%;" v-model.trim="email" :placeholder="$t('请输入')">
						</div>
					</div>
					<!-- 用户id -->
					<div v-if="agent_info.id == 14" class="main-li _pc-mar-bottom-25">
						<div class="_flex-row-mid">
							<img class="_pc-mar-right-10 _pc-w-15" src="~@/assets/image/hangda/logo/mobile-user-1.png" alt="" />
							<div class="_pc-font-16" style="color: #333333;">{{ $t('你的ID') }}</div>
						</div>
						<div class="input-box _pc-w-max _pc-h-50 _white-bg _flex-row-mid _pc-pad-x-25">
							<input type="text" style="width: 100%;" v-model.trim="user_code" :placeholder="$t('请输入')">
						</div>
						<div style="color: darkorange;">*{{ $t('请填写ID号段为900001-999999范围内的数字') }}</div>
					</div>
					<!-- 验证码 -->
					<div class="main-li _pc-mar-bottom-25">
						<div class="_pc-w-max">
							<div class="_flex-row-mid">
								<img class="_pc-mar-right-10 _pc-w-15" src="~@/assets/image/hangda/safe_code.png" alt="" />
								<div class="_pc-font-16" style="color: #333333;">{{$t('验证码')}}</div>
							</div>
							<div class="input-box-code _pc-h-50 _flex-row-bet-mid _pc-mar-top-10">
								<input type="text" class="_pc-w-280 _pc-pad-x-25" v-model.trim="safe_code" :placeholder="$t('请输入')">
								<button :class="{'safe-code-btn':show,'safe-code-btn2':!show || !email}" @click="getCode">{{ $t('获取验证码') }}<span v-if="!show">（{{ count }}s)</span></button>
							</div>
						</div>
						<div class="_pc-font-16 _pc-mar-top-15" style="color: #C81920;" v-if="!show">*{{ $t('验证码已发送至您的邮箱，请注意') }}</div>
					</div>
					<!-- 登录密码 -->
					<div class="main-li _pc-mar-bottom-25">
						<div class="_flex-row-mid">
							<img class="_pc-mar-right-10 _pc-w-15" src="~@/assets/image/hangda/logo/password.png" alt="" />
							<div class="_pc-font-16" style="color: #333333;">{{ $t('登录密码') }}</div>
						</div>
						<div class="input-box _pc-w-max _pc-h-50 _white-bg _flex-row-mid _pc-pad-x-25">
							<input type="password" style="width: 100%;" v-model.trim="password" :placeholder="$t('请输入')">
						</div>
					</div>
					<!-- 确认密码 -->
					<div class="main-li _pc-mar-bottom-25">
						<div class="_flex-row-mid">
							<img class="_pc-mar-right-10 _pc-w-15" src="~@/assets/image/hangda/logo/password.png" alt="" />
							<div class="_pc-font-16" style="color: #333333;">{{ $t('确认密码') }}</div>
						</div>
						<div class="input-box _pc-w-max _pc-h-50 _white-bg _flex-row-mid _pc-pad-x-25">
							<input type="password" style="width: 100%;" v-model.trim="check_password" :placeholder="$t('再次输入您的密码')">
						</div>
					</div>
				</div>
				<!-- H5 -->
				<div class="_mb-display-block _display-none _white-bg _mb-font-28 _mb-mar-bottom-40" style="border-radius:6px;">
					<div class="_border-bottom _flex-row-bet-mid _mb-pad-y-30 _mb-pad-x-30">
						<div class="_mb-mar-right-10" style="color: #333333;">{{ $t('电子邮箱') }}</div>
						<input type="text"  v-model.trim="email" :placeholder="$t('请输入')" class="_text-right _flex-1">
					</div>
					<div v-if="agent_info.id == 14" class="_border-bottom _mb-pad-y-30 _mb-pad-x-30">
						<div class="_flex-row-bet-mid">
							<div class="_mb-mar-right-10" style="color: #333333;">{{ $t('你的ID') }}</div>
							<input type="text"  v-model.trim="user_code" :placeholder="$t('请输入')" class="_text-right _flex-1">
						</div>
						<div style="color: darkorange;font-size: 10px;">*{{ $t('请填写ID号段为900001-999999范围内的数字') }}</div>
					</div>
					<div class="_border-bottom _flex-row-bet-mid _mb-pad-y-30 _mb-pad-x-30">
						<div class="_mb-mar-right-10" style="color: #333333;">{{ $t('验证码') }}</div>
						<div class="_flex-row-mid _flex-1">
							<input type="text"  v-model.trim="safe_code" :placeholder="$t('请输入')" class="_text-right _flex-1 _mb-w-120">
							<div class="_mb-pad-x-10">|</div>
							<button class="_border-left" :class="{'safe-code-btn':show,'safe-code-btn2':!show || !email}" @click="getCode">{{ $t('获取验证码') }}<span v-if="!show">({{ count }}s)</span></button>
						</div>
					</div>
					<div class="_border-bottom _flex-row-bet-mid _mb-pad-y-30 _mb-pad-x-30">
						<div class="_mb-mar-right-10" style="color: #333333;">{{ $t('登录密码') }}</div>
						<input type="password"  v-model.trim="password" :placeholder="$t('请输入')" class="_text-right _flex-1">
					</div>
					<div class="_flex-row-bet-mid _mb-pad-y-30 _mb-pad-x-30">
						<div class="_mb-mar-right-10" style="color: #333333;">{{ $t('确认密码') }}</div>
						<input type="password"  v-model.trim="check_password" :placeholder="$t('请输入')" class="_text-right _flex-1">
					</div>
				</div>
				<!-- 注册 -->
				<div @click="nextStep()" class="_pc-w-max _white _pc-font-16 _flex-row-center-mid _pc-h-50 _mb-h-70 _mb-font-28 _mb-mar-bottom-25" style="border-radius: 12px;background-color: #4284DC;cursor:pointer;">{{ agent_info.is_main ? $t('确认提交') : $t('下一步') }}</div>
				<div class="logio-box-footer _pc-mar-top-30 _mb-mar-top-25 _flex-row-ard-mid _pc-w-max _pc-pad-x-40 _mb-pad-x-0" style="color: #999;">
					<!-- 返回登录 -->
					<router-link class="left btn-route _mb-flex-row-center-mid _mb-mar-right-35 _mb-display-none" :to="'/login'">{{ $t('返回登录') }}</router-link>
					<span class="_mb-display-none">|</span>
					<!-- 找回密码 -->
					<router-link class="right btn-route _mb-flex-row-center-mid" :to="'/find_pwd'">{{ $t('找回密码') }}</router-link>
				</div>
			</div>


			<template v-if="tologin">
				<div class="mark-to-login">
					<div class="_white-bg _flex-col-mid success-box">
						<div class="_pc-font-32 _mb-font-36 _mb-b" style="color: #333;">{{ $t('注册成功') }}</div>
						<button class="to-login" @click="Login()">{{ $t('登录') }}</button>
					</div>
				</div>
			</template>
		</div>
		<!-- footer -->
		<div class="_pc-display-none" style="position: absolute;bottom: 0;left: 0;right: 0;">
			<FooterVueTwo></FooterVueTwo>
		</div>
	</div>
</template>

<script>
import { registerVerify, dataValidation, bannerList, main_register } from '@/api/jiyun';
import { getCookies } from "@/libs/util";
import FooterVueTwo from '../../components/FooterTwo.vue';
export default {
	components: {
		FooterVueTwo
	},
	data() {
		return {
			title: this.$t('注册'),
			click_get_code: false,
			click_get_code_count: 0,
			isCheck: false,
			email: '',
			safe_code: '',
			password: '',
			check_password: '',
			show: true,
			count: '',
			timer: null,
			user_code: '',
			is_consent: '',
			agent_info: {},
			tologin: false,
		}
	},
	created() {
		this.is_consent = getCookies('consent') == 'true' ? true : false;
		// if(!this.is_consent) {
		// 	this.$Message.info(this.$t('请阅读并同意协议'));
		// 	setTimeout(()=>{
		// 		this.$router.replace({
		// 			path: '/agreement',
		// 		})
		// 	},2000)
		// }
		this.getAgentInfo();
	},
	metaInfo() {
		return {
			title: this.title,
		};
	},
	methods: {
		// 登录
		Login() {
			let that = this;
			that.tologin = false;
			that.$router.replace({
				path: '/login',
			})
		},
		// 代理banner
		getAgentInfo(){
			let param = {
				domain_url: window.location.origin
			}
			bannerList(param).then((res)=>{
				this.agent_info = res.data;
				console.log(this.agent_info)
			}).catch((err)=>{
				this.$Message.error(err.msg);
			})
		},
		// 阅读协议
		toAgreement(){
			this.$router.push('/agreement')
		},
		// 同意协议
		checkAgreement(){
			this.isCheck = !this.isCheck;
		},
		// 发送验证码
		getCode() {
			let that = this;
			let param = {
				email: that.email,
				domain_url: window.location.origin
			}
			registerVerify(param).then((res)=>{
				that.$Message.success(res.msg)
				const TIME_COUNT = 60;
				if (!that.timer && that.show && that.email) {
					that.count = TIME_COUNT;
					that.show = false;
					that.timer = setInterval(() => {
						if (that.count > 0 && that.count <= TIME_COUNT) {
							that.count -= 1;
						} else {
							that.show = true;
							clearInterval(that.timer);
							that.timer = null;
						}
					}, 1000);
				}
			}).catch((err)=>{
				this.$Message.error(err.msg);
			})
		},
		// 下一步
		nextStep() {
			let that = this;
			if(!that.email) {
				return that.$Message.info(that.$t('请输入邮箱'));
			}
			if(that.agent_info == 14){
				if(!that.user_code){
					return that.$Message.info(that.$t('请输入你的ID'))
				}
			}
			if(!that.safe_code) {
				return that.$Message.info(that.$t('请输入验证码'));
			}
			if(!that.password) {
				return that.$Message.info(that.$t('请输入登录密码'));
			}
			if(!that.check_password) {
				return that.$Message.info(that.$t('请输入确认密码'));
			}
			let param = {
				email: that.email,
				code: that.safe_code,
				password: that.password,
				affirm_pwd: that.check_password,
				user_code: that.user_code,
				domain_url: window.location.origin
			}
			dataValidation(param).then((res)=>{
				if(that.agent_info.is_main){// 主账号
					let params = {
						email: that.email,
						password: that.password,
						domain_url: window.location.origin,
					}
					main_register(params).then((res)=>{
						that.tologin = true;
					}).catch((err)=>{
						that.$Message.error(err.msg);
					})
				} else {
					that.$router.push({
						name: 'addressInfo',
						params: {
							email: that.email,
							password: that.password,
							user_code: that.user_code,
							is_consent: that.is_consent
						} 
					})
				}
			}).catch((err)=>{
				that.$Message.error(err.msg);
			})
		},
	}
}
</script>

<style scoped>
.mark-to-login {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
}

.to-login {
	width: 279px;
	/* height: 62px; */
	background: #4284DC;
	border-radius: 12px;
	font-size: 20px;
	color: #ffffff;
	padding-top: 15px;
	padding-bottom: 15px;
	border: none;
	margin-top: 20px;
}
a {
	text-decoration: unset;
	color: unset;
}

a:hover {
	color: unset;
}

a:visited {
	color: unset;
}

a:active {
	color: unset;
}

input {
	border-radius: 7px;
	border: none;
	/* width: 100%; */
	height: 100%;
}

input:focus {
	outline: none;
}

.index {
	width: 100%;
	min-height: 100%;
	background-image: url(../../assets/image/hangda/logo/logo_bg.png) !important;
	background-size: 100% 100% !important;
	background-repeat: no-repeat !important;
	/* overflow: scroll;
	overflow-x: hidden; 
	scrollbar-width: none;
	-ms-overflow-style: none; */
	padding-bottom: 80px;
}

.index::-webkit-scrollbar {
	display: none;
	/* Chrome Safari */
}

.input-box {
	border-radius: 7px;
	margin-top: 11px;
}
.input-box:hover {
	outline:1px solid rgba(66, 132, 220, 0.7);
}
.input-box-code input:hover {
	outline:1px solid rgba(66, 132, 220, 0.7);
}
.safe-code-btn {
	border-radius: 7px;
	background-color: #D8E4F3;
	border: 1px solid #4284DC;
	color: #4284DC;
	width: 175px;
	height: 100%;
	font-size: 18px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.safe-code-btn:hover {
	border: 1px solid #D8E4F3;
	background-color: #4285dc56;
	color: #D8E4F3;
	cursor: pointer;
}
.safe-code-btn2 {
	border-radius: 7px;
	border: 1px solid #D8E4F3;
	background-color: #4285dc56;
	color: #FFF;
	width: 175px;
	height: 100%;
	font-size: 18px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.success-box{
	border-radius: 16px;
	padding: 50px;
}
@media screen and (max-device-width: 768px) {
	.safe-code-btn {
		border-radius: 3.5px;
		width: 24vw;
		height: 3.80518vh;
		font-size: 2.93333vw;
	}

	.safe-code-btn2 {
		border-radius: 3.5px;
		width: 24vw;
		height: 3.80518vh;
		font-size: 2.93333vw;
	}

	.btn-route{
		color: rgba(66, 132, 220, 1) !important;
		border:1px solid rgba(66, 132, 220, 1);
		border-radius: 6px;
		flex: 1;
		height: 5.32725vh;
	}

	.success-box{
		border-radius: 8px;
		padding: 8.66667vw;
	}

	.to-login {
		width: 33.33333vw;
		border-radius: 6px;
		font-size: 3.73333vw;
		padding-top: 1.90259vh;
		padding-bottom: 1.90259vh;
		margin-top: 2.28311vh;
	}
}
</style>